import * as React from 'react';
import { FunctionComponent, ReactNode, useRef, useState } from 'react';

import {
	Box,
	Icon,
	Inline,
	MinimalModal,
	Section,
	Text,
	useTextStyles,
} from '@autoguru/overdrive';
import clsx from 'clsx';
import { AlertCircleIcon } from '@autoguru/icons';

interface Props {
	errorFallback?: ReactNode;
	errorMessage?: string;
	browsers: RegExp;
	children: ReactNode;
}

//@ts-ignore
export const SupportedBrowsersBoundary: FunctionComponent<Props> = ({
	children,
	errorFallback,
	browsers,
	errorMessage = 'Your browser is out of date and might not support all the features of this app.',
}) => {
	const isSupported = useRef(browsers.test(navigator.userAgent));

	const [showStats, setShowStats] = useState<boolean>(!isSupported.current);

	return isSupported.current ? (
		<>{children}</>
	) : errorFallback ? (
		errorFallback
	) : (
		<>
			<MinimalModal
				hideBackdrop
				onRequestClose={() => setShowStats(false)}
				isOpen={showStats}>
				<Box
					width="full"
					padding="3"
					backgroundColour="white"
					borderRadius="1">
					<Section width="small">
						<Inline noWrap space="3">
							<Icon
								className={clsx(
									useTextStyles({
										colour: 'warning',
									}),
								)}
								size="large"
								icon={AlertCircleIcon}
							/>
							<Text size="3">{errorMessage}</Text>
						</Inline>
					</Section>
				</Box>
			</MinimalModal>
			{children}
		</>
	);
};
