import { UserMenuHolder } from '@autoguru/components';
import { Box, Column, Columns, Text } from '@autoguru/overdrive';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { HeaderLayout } from '@autoguru/header-layout';
import { Picture } from '@autoguru/picture';
import { CDN, RETAIL_URL } from '@autoguru/global-configs';

export const pageHeaderPortalId = '__ag-header__';

const LogoImage = (
	<Picture
		aspectRatio={5.1}
		height={24}
		backgroundColor="transparent"
		src={`${CDN}/images/logos/autoguru.svg`}
		altText="AutoGuru logo"
	/>
);

export const AppHeader: FunctionComponent = () => {
	return (
		<HeaderLayout safePaddings>
			<Columns space="5">
				<Column noShrink alignSelf="centre">
					<Box is="a" href={RETAIL_URL}>
						{LogoImage}
					</Box>
				</Column>
				<Column grow alignSelf="centre">
					<Columns width="full" space="3">
						<Column grow alignSelf="centre">
							<span />
						</Column>
						<Column noShrink alignSelf="centre">
							<Box
								is="a"
								href={`${RETAIL_URL}/contact-us`}
								target="_blank"
								rel="noopener noreferrer"
								role="link"
								aria-label="Contact AutoGuru"
							>
								<Text size="3">Contact us</Text>
							</Box>
						</Column>
					</Columns>
				</Column>
				<Column noShrink alignSelf="centre">
					<UserMenuHolder />
				</Column>
			</Columns>
		</HeaderLayout>
	);
};
