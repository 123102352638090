import clsx from 'clsx';
import * as React from 'react';
import { ComponentProps, Dispatch, forwardRef, MutableRefObject, SetStateAction, useCallback, useState } from 'react';

import * as styles from './Picture.css';
import { Box } from '@autoguru/overdrive';
import { logEvent, useLoggingAgent } from '@autoguru/logs';

interface Props
	extends Partial<
		Omit<ComponentProps<typeof Box>, 'is' | 'width' | 'height'>
	> {
	src: string;
	srcset?: string;
	fallbackSrc?: string;
	fallbackImageWidth?: string | number;
	aspectRatio: number;
	altText: string;
	alt?: string;
	backgroundColor?: string;
	imageSizes?: string;
	lazy?: boolean;
	flipped?: boolean;
	objectFit?: 'scale-down' | 'none' | 'contain' | 'cover' | 'fill';
	objectPosition?: 'bottom' | 'center' | 'left' | 'right' | 'top';
	wrapperRef?: MutableRefObject<HTMLDivElement>;
	className?: string;
	width?: number | string;
	height?: number | string;
	manufacturer?: string;
	showMakeVehicleFallback?: Dispatch<SetStateAction<boolean>>;
}

export const Picture = forwardRef<HTMLImageElement, Props>(
	(
		{
			src,
			fallbackSrc,
			fallbackImageWidth,
			aspectRatio,
			altText,
			backgroundColor,
			backgroundColour,
			className = '',
			objectFit,
			objectPosition,
			height,
			width,
			wrapperRef,
			flipped = false,
			lazy = true,
			srcset,
			borderRadius,
			manufacturer,
			showMakeVehicleFallback,
			...rest
		},
		targetRef,
	) => {
		const agent = useLoggingAgent();
		//const imgWidth = width ? width : height ? 'auto' : '100%';
		const [imgWidth, setImgWidth] = useState(width ? width : height ? 'auto' : '100%');
		const fixedHeight = Boolean(height);
		const isPortrait = aspectRatio < 1;
		const showFallback = useCallback(
			(e) => {
				setImgWidth(fallbackImageWidth || imgWidth);
				if (e.currentTarget.getAttribute('src') === fallbackSrc) {
					logEvent({
						level: 'info',
						type: 'MISSING_MANUFACTURER_LOGO',
						category: 'Picture',
						data: {
							manufacturer: manufacturer,
						}
					}, agent);
					return
				}
				e.currentTarget.setAttribute('src', fallbackSrc);
				e.preventDefault();
				e.stopPropagation();
			},
			[fallbackSrc, fallbackImageWidth, imgWidth, showMakeVehicleFallback],
		);

		const [imgSrc, setImgSrc] = useState(src);

		const handleError = (e) => {
			setImgWidth(fallbackImageWidth || imgWidth);
			if (imgSrc !== fallbackSrc) {
				setImgSrc(fallbackSrc);
			} else {
				showMakeVehicleFallback(false);
				showFallback(e);
			}
		};

		return (
			<Box
				ref={wrapperRef}
				className={clsx(className, styles.frame, {
					[styles.flipped]: Boolean(flipped),
					[styles.fixedHeight]: fixedHeight,
				})}
				display={fixedHeight ? 'flex' : null}
				alignItems={fixedHeight ? 'center' : null}
				justifyContent={fixedHeight ? 'center' : null}
				flexDirection={fixedHeight ? 'row' : null}
				height={fixedHeight ? 'full' : null}
				borderRadius={borderRadius}
				overflow="hidden"
				backgroundColour={
					backgroundColor ? null : backgroundColour || 'gray200'
				}
				style={{
					paddingBottom: height
						? undefined
						: `${100 * (1 / aspectRatio)}%`,
					height,
					width: imgWidth,
					backgroundColor,
				}}
			>
				{Boolean(src) && (
					<img
						// @ts-ignore
						ref={targetRef}
						loading={lazy ? 'lazy' : void 0}
						width={aspectRatio ? 100 * aspectRatio : width}
						height={aspectRatio ? 100 : height}
						src={imgSrc}
						aspectratio={aspectRatio}
						srcSet={srcset}
						{...rest}
						alt={altText || rest.alt}
						style={{
							objectPosition,
							objectFit,
							height: objectFit && isPortrait ? 'auto' : height,
							width: objectFit && !isPortrait ? 'auto' : imgWidth,
						}}
						onError={fallbackSrc && showMakeVehicleFallback ? handleError : fallbackSrc ? showFallback : null}
					/>
				)}
			</Box>
		);
	},
);

export default Picture;
