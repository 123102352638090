import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';
import { getFeatureName } from '@autoguru/feature-identification';
import { ENV_NAME, TENANT_NAME } from '@autoguru/global-configs';
import { ErrorWithGraphQLErrors } from './types';


export const buildLogContext = (contexts: any = {}) => {
	return {
		...contexts,
		featureName: getFeatureName(),
		mfeAppName: __GDU_APP_NAME__,
		ag_env: ENV_NAME,
		ag_tenant: TENANT_NAME,
	};
};

type BreadCrumbData = {
	[key: string]: any;
	errors?: ErrorWithGraphQLErrors[] | ErrorWithGraphQLErrors | any;
}

type BreadcrumbType = 'MISSING_MANUFACTURER_LOGO' | 'MISSING_CATEGORY_ICON';
type Level = 'info' | 'warn' | 'error';

type Breadcrumb = {
	type: BreadcrumbType | string;
	level: Level;
	category: string;
	data?: BreadCrumbData;
}

export const logEvent = (breadcrumb: Breadcrumb, agent: MicroAgent) => {
	if (!agent)
		return;
	const context = buildLogContext();
    agent.addPageAction(breadcrumb.category, {
      type: breadcrumb.type,
      level: breadcrumb.level,
      data: JSON.stringify(breadcrumb.data),
		...context,
    });
	// Log exceptions if breadcrumb contains errors
	if (breadcrumb.data?.errors) {
		const errors = breadcrumb.data.errors;
		if (Array.isArray(errors)) {
			errors.forEach((error) => {
				if (error instanceof Error) {
					agent.noticeError(error, {
						...context,
					});
				}
			});
		} else if (errors instanceof Error) {
			agent.noticeError(errors, {
				...context,
			});
		}
	}
};
