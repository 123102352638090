import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box } from '@autoguru/overdrive';

export const userMenuElementId = '__ag-user-menu__';
export const UserMenuHolder: FunctionComponent = () => (
	<Box
		role="navigation"
		aria-label="Main aside"
		is="nav"
		id={userMenuElementId}
	/>
);
