import { ContactNumbersProvider } from '@autoguru/components';
import { Portal, StickyBox } from '@autoguru/overdrive';
import * as React from 'react';
import {
	AppHeader,
	pageHeaderPortalId,
	SimpleAppHeader,
} from '~/src/components';
import { useParams } from 'react-router-dom';
import { isAutoGuruSlug } from '@autoguru/mygarage-check-slug';

export const App = () => {
	const { slug } = useParams();
	const isAutoGuru = isAutoGuruSlug(slug);

	return (
		<ContactNumbersProvider>
			<Portal
				container={document.querySelector(`#${pageHeaderPortalId}`)}
			>
				<StickyBox top="none" zIndex={1}>
					{isAutoGuru ? <AppHeader /> : <SimpleAppHeader />}
				</StickyBox>
			</Portal>
		</ContactNumbersProvider>
	);
};
