import '@autoguru/overdrive/dist/reset/globalReset.css';
import '@autoguru/overdrive/dist/reset/globalFonts.css';
import * as React from 'react';
import { StrictMode } from 'react';
import { LoggingAgentProvider } from '@autoguru/logs';

import { createRoot } from 'react-dom/client';

import { App } from './App';
import { themeRef } from '@autoguru/overdrive/dist/themes/base/vars.css';
import { themeContractVars as vars } from '@autoguru/overdrive/dist/themes/theme.css';

import { OverdriveProvider } from '@autoguru/overdrive';
import { SupportedBrowsersBoundary } from '@autoguru/supported-browser-boundary';
import { AGSupportedBrowsers } from '~/browsers';
import { getMfeMountPoint } from 'gdu/dist/lib/mfe';

import * as guruConfigs from '../guru.config';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = getMfeMountPoint({
	mountDOMId: guruConfigs.mountDOMId,
	projectName: __GDU_APP_NAME__,
});
const MFE = () => {
	createRoot(root).render(
		<StrictMode>
			<LoggingAgentProvider>
				<OverdriveProvider
					noBodyLevelTheming={false}
					themeClass={themeRef}
					vars={vars}
				>
					<SupportedBrowsersBoundary browsers={AGSupportedBrowsers}>
						<Router>
							<Routes>
								<Route path={`/:slug/*`} element={<App />} />
							</Routes>
						</Router>
					</SupportedBrowsersBoundary>
				</OverdriveProvider>
			</LoggingAgentProvider>
		</StrictMode>,
	);
};

export default MFE;
