import { UserMenuHolder } from '@autoguru/components';
import { Column, Columns } from '@autoguru/overdrive';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { HeaderLayout } from '@autoguru/header-layout';
import { Picture } from '@autoguru/picture';
import { CDN } from '@autoguru/global-configs';

const LogoImage = (
	<Picture
		aspectRatio={5.1}
		height={48}
		backgroundColor="transparent"
		src={`${CDN}/images/logos/otto.svg`}
		altText="Otto logo"
	/>
);

export const SimpleAppHeader: FunctionComponent = () => (
	<HeaderLayout safePaddings>
		<Columns space="5">
			<Column noShrink alignSelf="centre">
				{LogoImage}
			</Column>
			<Column grow alignSelf="centre">
				<Columns width="full" space="3">
					<span />
				</Columns>
			</Column>
			<Column noShrink alignSelf="centre">
				<UserMenuHolder />
			</Column>
		</Columns>
	</HeaderLayout>
);
